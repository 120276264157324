import { defineStore } from 'pinia'
import { useLocalStorage, useIntervalFn } from '@vueuse/core'
import { LocalStorageKey } from '~/types/localStorage'
import { CoinMarket } from '~/types/feeder'
import useFeederAPI from '~/composables/useFeederAPI'
import { MARKETS_POLLING_INTERVAL, WALLET_BALANCE_OPTIONS } from '~/data/cryptoMarket'

export type Coin = Pick<CoinMarket, 'id' | 'symbol' | 'name' | 'image'>

const convertFiatCurrency = (amount: number, conversion: any) => {
  return (amount / conversion.data.value.rates.EUR) * conversion.data.value.rates.CHF
}

export const useCryptoMarketStore = defineStore('CryptoMarketStore', {
  state: () => ({
    coins: useLocalStorage(LocalStorageKey.Coins, []) as unknown as Coin[],
    markets: [] as CoinMarket[],
    marketsError: false,
    pause: undefined as (() => void) | undefined,
    resume: undefined as (() => void) | undefined
  }),
  getters: {
    getCoins(state) {
      return (...ids: string[]) => state.coins.filter(coin => ids.includes(coin.id))
    },
    getCoinsBySymbol(state) {
      return (...symbols: string[]) => state.coins.filter(coin => symbols.includes(coin.symbol))
    }
  },
  actions: {
    async fetchCoins() {
      if (this.coins.length) {
        return
      }
      const { getMarkets } = useFeederAPI()
      const { data } = await getMarkets()
      if (data.value) {
        this.coins = data.value
          .filter(item => WALLET_BALANCE_OPTIONS.includes(item.id))
          .map(({ id, symbol, name, image }) => ({ id, symbol, name, image }))
      }
    },
    async fetchMarkets() {
      const { getMarkets, getConversion } = useFeederAPI()
      const [markets, conversion] = await Promise.all([getMarkets(), getConversion()])
      if (markets.data.value) {
        this.markets = markets.data.value.map(market => ({
          ...market,
          ...{
            current_price: convertFiatCurrency(market.current_price, conversion),
            market_cap: convertFiatCurrency(market.market_cap, conversion),
            fully_diluted_valuation:
              market.fully_diluted_valuation &&
              convertFiatCurrency(market.fully_diluted_valuation, conversion),
            total_volume: convertFiatCurrency(market.total_volume, conversion)
          }
        }))
        this.marketsError = false
      } else {
        this.marketsError = true
      }
    },
    startMarketsPolling() {
      this.fetchMarkets()
      if (this.resume) {
        this.resume()
      } else {
        const { pause, resume } = useIntervalFn(
          () => this.fetchMarkets(),
          MARKETS_POLLING_INTERVAL,
          {
            immediate: true,
            immediateCallback: true
          }
        )
        this.pause = pause
        this.resume = resume
      }
    },
    stopMarketsPolling() {
      if (this.pause) {
        this.pause()
      }
    }
  },
  hydrate(storeState) {
    // in this case we can completely ignore the initial state and we read from the browser
    // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/43826
    storeState.coins = useLocalStorage(LocalStorageKey.Coins, [])
  }
})
