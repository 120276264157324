
// @ts-nocheck


export const localeCodes =  [
  "de-ch",
  "en-ch",
  "fr-ch",
  "it-ch"
]

export const localeMessages = {
  "de-ch": [{ key: "../locales/de-ch.json", load: () => import("../locales/de-ch.json" /* webpackChunkName: "locale__opt_build_repo_locales_de_ch_json" */), cache: true }],
  "en-ch": [{ key: "../locales/en-ch.json", load: () => import("../locales/en-ch.json" /* webpackChunkName: "locale__opt_build_repo_locales_en_ch_json" */), cache: true }],
  "fr-ch": [{ key: "../locales/fr-ch.json", load: () => import("../locales/fr-ch.json" /* webpackChunkName: "locale__opt_build_repo_locales_fr_ch_json" */), cache: true }],
  "it-ch": [{ key: "../locales/it-ch.json", load: () => import("../locales/it-ch.json" /* webpackChunkName: "locale__opt_build_repo_locales_it_ch_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n/i18n.config.ts?hash=5b32d2e0&config=1" /* webpackChunkName: "__i18n_i18n_config_ts_5b32d2e0" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n/i18n.config.ts",
  "locales": [
    {
      "code": "de-ch",
      "lang": "de",
      "iso": "de-CH",
      "countryName": "Schweiz",
      "languageName": "Deutsch",
      "files": [
        "locales/de-ch.json"
      ]
    },
    {
      "code": "en-ch",
      "lang": "en",
      "iso": "en-CH",
      "countryName": "Switzerland",
      "languageName": "English",
      "files": [
        "locales/en-ch.json"
      ]
    },
    {
      "code": "fr-ch",
      "lang": "fr",
      "iso": "fr-CH",
      "countryName": "Suisse",
      "languageName": "Francais",
      "files": [
        "locales/fr-ch.json"
      ]
    },
    {
      "code": "it-ch",
      "lang": "it",
      "iso": "it-CH",
      "countryName": "Svizzera",
      "languageName": "Italiano",
      "files": [
        "locales/it-ch.json"
      ]
    }
  ],
  "defaultLocale": "de-ch",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "locales",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://www.cryptonow.ch",
  "dynamicRouteParams": true,
  "customRoutes": "config",
  "pages": {
    "[slug]": {
      "en-de": "/[slug]",
      "de-ch": "/[slug]",
      "en-ch": "/[slug]",
      "fr-ch": "/[slug]",
      "it-ch": "/[slug]"
    },
    "discover": {
      "en-de": "/discover",
      "de-ch": "/discover",
      "en-ch": "/discover",
      "fr-ch": "/decouvrir",
      "it-ch": "/scoprire"
    },
    "discover/[slug]": {
      "en-de": "/discover/[slug]",
      "de-ch": "/discover/[slug]",
      "en-ch": "/discover/[slug]",
      "fr-ch": "/decouvrir/[slug]",
      "it-ch": "/scoprire/[slug]"
    },
    "discover/[category]/[slug]": {
      "en-de": "/discover/[category]/[slug]",
      "de-ch": "/discover/[category]/[slug]",
      "en-ch": "/discover/[category]/[slug]",
      "fr-ch": "/decouvrir/[category]/[slug]",
      "it-ch": "/scoprire/[category]/[slug]"
    },
    "hilfe": {
      "en-de": "/help",
      "de-ch": "/hilfe",
      "en-ch": "/help",
      "fr-ch": "/assistance",
      "it-ch": "/supporto"
    },
    "hilfe/[slug]": {
      "en-de": "/help/[slug]",
      "de-ch": "/hilfe/[slug]",
      "en-ch": "/help/[slug]",
      "fr-ch": "/assistance/[slug]",
      "it-ch": "/supporto/[slug]"
    },
    "hilfe/[topic]/[category]/[slug]": {
      "en-de": "/help/[topic]/[category]/[slug]",
      "de-ch": "/hilfe/[topic]/[category]/[slug]",
      "en-ch": "/help/[topic]/[category]/[slug]",
      "fr-ch": "/assistance/[topic]/[category]/[slug]",
      "it-ch": "/supporto/[topic]/[category]/[slug]"
    },
    "globaler-kryptomarkt": {
      "en-de": "/global-crypto-market",
      "de-ch": "/globaler-kryptomarkt",
      "en-ch": "/global-crypto-market",
      "fr-ch": "/marche-mondial-des-cryptomonnaies",
      "it-ch": "/mercato-globali-delle-criptovalute"
    },
    "globaler-kryptomarkt/[slug]": {
      "en-de": "/global-crypto-market/[slug]",
      "de-ch": "/globaler-kryptomarkt/[slug]",
      "en-ch": "/global-crypto-market/[slug]",
      "fr-ch": "/marche-mondial-des-cryptomonnaies/[slug]",
      "it-ch": "/mercato-globali-delle-criptovalute/[slug]"
    },
    "vergleiche-krypto-projekte/index": {
      "en-de": "/compare-crypto-assets",
      "de-ch": "/vergleiche-krypto-projekte",
      "en-ch": "/compare-crypto-assets",
      "fr-ch": "/outil-de-comparaison-des-cryptos",
      "it-ch": "/tool-di-confronto-per-criptovalute"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "de-ch",
      "lang": "de",
      "iso": "de-CH",
      "countryName": "Schweiz",
      "languageName": "Deutsch",
      "files": [
        {
          "path": "locales/de-ch.json"
        }
      ]
    },
    {
      "code": "en-ch",
      "lang": "en",
      "iso": "en-CH",
      "countryName": "Switzerland",
      "languageName": "English",
      "files": [
        {
          "path": "locales/en-ch.json"
        }
      ]
    },
    {
      "code": "fr-ch",
      "lang": "fr",
      "iso": "fr-CH",
      "countryName": "Suisse",
      "languageName": "Francais",
      "files": [
        {
          "path": "locales/fr-ch.json"
        }
      ]
    },
    {
      "code": "it-ch",
      "lang": "it",
      "iso": "it-CH",
      "countryName": "Svizzera",
      "languageName": "Italiano",
      "files": [
        {
          "path": "locales/it-ch.json"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

