<template>
  <component
    :is="to ? NuxtLink : 'button'"
    ref="buttonRef"
    :to="to ? to : undefined"
    class="group relative"
    :class="variant !== 'unstyled' && classList"
    :external="to && external"
    :type="to ? undefined : type"
    @click="onClick"
  >
    <Spinner
      v-if="loading"
      class="absolute left-1/2 top-1/2 h-6px w-6px -translate-x-1/2 -translate-y-1/2"
      color="white"
    />

    {{ label }}
    <slot v-if="!label" />

    <Icon
      v-if="icon"
      :name="typeof icon === 'string' ? icon : icon.name"
      :size="icon.size"
      :color="typeof icon === 'string' ? 'white group-hover:black ' : icon.color"
      :class="[
        {
          'ml-1': size === 'md' && label,
          'ml-3 h-8px w-8px md:ml-3.5':
            variant === 'link' && icon.name === 'circle-chevron-right-general',
          'shrink-0 transition-colors group-hover:text-orange': variant === 'link'
        },
        icon.className
      ]"
    />
  </component>
</template>

<script setup lang="ts">
import { IconSize, IconName } from '~/components/media/Icon.vue'
const NuxtLink = resolveComponent('NuxtLink')

export type ButtonVariant = 'ghost' | 'outline' | 'solid' | 'link' | 'unstyled'

export interface ButtonProps {
  disabled?: boolean // functional
  isDisabled?: boolean // style
  icon?: {
    color?: string
    name: IconName
    size?: IconSize
    className?: string
  }
  variant?: ButtonVariant
  label?: string | null
  to?: string | object | undefined | null
  size?: 'md' | 'lg'
  scalable?: boolean
  color?: 'black' | 'orange' | 'white'
  loading?: boolean
  external?: boolean
  type?: 'button' | 'submit' | 'reset'
}

const props = withDefaults(defineProps<ButtonProps>(), {
  disabled: false,
  isDisabled: false,
  variant: 'solid',
  size: 'md',
  scalable: true,
  color: 'black',
  type: 'button'
})

const buttonRef = ref<HTMLElement | null>(null)

defineExpose({
  buttonRef
})

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (event: 'click', e: MouseEvent): void
}>()

const $style = useCssModule()

const classList = computed(() => {
  return [
    $style.button,
    {
      // Style
      ' bg-black border-black text-white hover:bg-neutral-800':
        props.variant === 'solid' && props.color === 'black',
      [$style.outline]: props.variant === 'outline',
      [$style.ghost]: props.variant === 'ghost',
      [$style.link]: props.variant === 'link',

      // Size
      [$style.medium]: props.size === 'md',
      [$style.large]: props.size === 'lg',

      // Scalable
      [$style.scalable]: props.scalable,

      // Color
      'bg-orange border-orange text-black hover:bg-orange-400 before:!border-orange':
        props.variant === 'solid' && props.color === 'orange',

      'bg-white border-white text-black hover:bg-orange hover:border-orange before:!border-black':
        props.variant === 'solid' && props.color === 'white',

      // 😇 Halo
      [$style.halo]: props.variant === 'solid' || props.variant === 'outline',

      // State
      '!bg-neutral-150 !border-neutral-150 !text-neutral-300': props.isDisabled,
      'pointer-events-none cursor-default': props.disabled || props.loading,
      '!text-transparent': props.loading
    }
  ]
})

function onClick(event: MouseEvent) {
  if (props.disabled) {
    event.preventDefault()
    event.stopPropagation()
  }
  emit('click', event)
}
</script>

<style lang="postcss" module>
.button {
  -webkit-tap-highlight-color: transparent;

  @apply relative inline-flex w-fit cursor-pointer items-center justify-center whitespace-nowrap rounded-full border no-underline outline-none transition-colors duration-300;
}

/* Outline */
.outline {
  @apply border-black bg-transparent text-black hover:bg-neutral-200;
}

.ghost {
  @apply border-transparent bg-transparent;

  &:not(.scalable) {
    @apply !p-0 !text-button-18-regular-px;
  }
}

.link {
  @apply whitespace-normal border-transparent bg-transparent text-button-20-regular md:text-button-25-regular;
}

/* Sizes */
.medium {
  &:not(.link) {
    &.scalable {
      @apply px-[1.688rem] py-[0.688rem] text-button-20-regular md:px-[1.688rem];
    }

    &:not(.scalable) {
      @apply px-7px pb-[13px] pt-[11px] text-button-20-regular-px;
    }
  }
}

.large {
  &:not(.link) {
    &.scalable {
      @apply px-[1.688rem] py-[0.688rem] text-button-20-regular md:px-[2.938rem] md:py-[0.938rem] md:text-button-22-regular;
    }

    &:not(.scalable) {
      @apply px-7px pb-[13px] pt-[11px] text-button-20-regular-px md:px-12px md:pb-[13px] md:pt-[15px] md:text-button-22-regular;
    }
  }
}

.halo {
  @apply relative;

  &::before {
    content: '';
    @apply absolute -left-[4px] -top-[4px] -z-1 h-[calc(100%+8px)] w-[calc(100%+8px)] rounded-full border border-purple opacity-0 transition-opacity duration-300 active:opacity-100;
  }
}
</style>
