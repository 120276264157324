import { defineStore } from 'pinia'
import { useGtm } from '@gtm-support/vue-gtm'

export const useModalStore = defineStore('ModalStore', {
  state: () => ({
    showWelcome: false,
    loadVoucherActivation: false,
    showVoucherActivation: false
  }),
  actions: {
    setShowWelcome(showWelcome: boolean) {
      this.showWelcome = showWelcome
    },
    setShowVoucherActivation(showVoucherActivation: boolean) {
      if (!this.loadVoucherActivation) {
        this.loadVoucherActivation = true
      }

      this.showVoucherActivation = showVoucherActivation

      // Disable GTM while VA modal is open
      const gtm = useGtm()

      if (!gtm?.enabled()) {
        return
      }

      if (showVoucherActivation) {
        gtm?.enable(false)
      } else {
        gtm?.enable(true)
      }
    }
  }
})
