import { convertToDatoCMSGraphQLLocaleCode } from '~/i18n/locales'
import { LocaleCode } from '~/types/locales'

export default function () {
  const { locale } = useI18n()

  /**
   * Coverts to a DatoCMS GraphQL locale code.
   */
  const localeDato = computed(() => convertToDatoCMSGraphQLLocaleCode(locale.value as LocaleCode))

  return {
    localeDato
  }
}
