import { defineNuxtPlugin } from '#app/nuxt'
import { LazySectionCta, LazyRelatedArticles, LazySectionArticleBody, LazySectionArticleCta, LazySectionArticles, LazySectionFeaturedArticles, LazySectionTopStories, LazySectionAwards, LazySectionCompareAssets, LazySectionCryptoMarket, LazySectionGraph, LazySectionWalletBalance, LazySectionDiscoverCategories, LazySectionDiscoverHero, LazySectionDiscoverMore, LazySectionEducational, LazySectionQuickGuides, LazySectionFeatures, LazySectionForm, LazySectionNewsletter, LazySectionThankYou, LazySectionGlossary, LazySectionHelpLinks, LazySectionHelpQuestions, LazySectionRelatedHelpArticles, LazySectionHero, LazySectionHeroBasic, LazySectionHeroContact, LazySectionHeroFullscreen, LazySectionHeroRocket, LazySectionPage, LazySectionCardLayers, LazySectionImmersiveTitle, LazySectionLogos, LazySectionMediaFullscreen, LazySectionCards, LazySectionStickyScroll, LazyStickyScrollDesktop, LazyStickyScrollMobile, LazyMenuCallToAction, LazyMenuGroup, LazySectionPartner, LazySectionStoreFinder, LazySectionSearch, LazySectionSearchTopics, LazySectionAppSlides, LazySectionBenefits, LazySectionCustomers, LazySectionFaq, LazySectionJobs, LazySectionMobileApp, LazySectionNumbers, LazySectionPress, LazySectionRegulations, LazySectionTable, LazySectionTabsScroll, LazySectionTeaser, LazySectionValue, LazySectionValueInverse, LazySectionValues, LazySectionVideo, LazySectionCompare, LazySectionTeam, LazySectionTestimonials, LazySectionHeadline, LazySectionTimeMachine } from '#components'
const lazyGlobalComponents = [
  ["SectionCta", LazySectionCta],
["RelatedArticles", LazyRelatedArticles],
["SectionArticleBody", LazySectionArticleBody],
["SectionArticleCta", LazySectionArticleCta],
["SectionArticles", LazySectionArticles],
["SectionFeaturedArticles", LazySectionFeaturedArticles],
["SectionTopStories", LazySectionTopStories],
["SectionAwards", LazySectionAwards],
["SectionCompareAssets", LazySectionCompareAssets],
["SectionCryptoMarket", LazySectionCryptoMarket],
["SectionGraph", LazySectionGraph],
["SectionWalletBalance", LazySectionWalletBalance],
["SectionDiscoverCategories", LazySectionDiscoverCategories],
["SectionDiscoverHero", LazySectionDiscoverHero],
["SectionDiscoverMore", LazySectionDiscoverMore],
["SectionEducational", LazySectionEducational],
["SectionQuickGuides", LazySectionQuickGuides],
["SectionFeatures", LazySectionFeatures],
["SectionForm", LazySectionForm],
["SectionNewsletter", LazySectionNewsletter],
["SectionThankYou", LazySectionThankYou],
["SectionGlossary", LazySectionGlossary],
["SectionHelpLinks", LazySectionHelpLinks],
["SectionHelpQuestions", LazySectionHelpQuestions],
["SectionRelatedHelpArticles", LazySectionRelatedHelpArticles],
["SectionHero", LazySectionHero],
["SectionHeroBasic", LazySectionHeroBasic],
["SectionHeroContact", LazySectionHeroContact],
["SectionHeroFullscreen", LazySectionHeroFullscreen],
["SectionHeroRocket", LazySectionHeroRocket],
["SectionPage", LazySectionPage],
["SectionCardLayers", LazySectionCardLayers],
["SectionImmersiveTitle", LazySectionImmersiveTitle],
["SectionLogos", LazySectionLogos],
["SectionMediaFullscreen", LazySectionMediaFullscreen],
["SectionCards", LazySectionCards],
["SectionStickyScroll", LazySectionStickyScroll],
["StickyScrollDesktop", LazyStickyScrollDesktop],
["StickyScrollMobile", LazyStickyScrollMobile],
["MenuCallToAction", LazyMenuCallToAction],
["MenuGroup", LazyMenuGroup],
["SectionPartner", LazySectionPartner],
["SectionStoreFinder", LazySectionStoreFinder],
["SectionSearch", LazySectionSearch],
["SectionSearchTopics", LazySectionSearchTopics],
["SectionAppSlides", LazySectionAppSlides],
["SectionBenefits", LazySectionBenefits],
["SectionCustomers", LazySectionCustomers],
["SectionFaq", LazySectionFaq],
["SectionJobs", LazySectionJobs],
["SectionMobileApp", LazySectionMobileApp],
["SectionNumbers", LazySectionNumbers],
["SectionPress", LazySectionPress],
["SectionRegulations", LazySectionRegulations],
["SectionTable", LazySectionTable],
["SectionTabsScroll", LazySectionTabsScroll],
["SectionTeaser", LazySectionTeaser],
["SectionValue", LazySectionValue],
["SectionValueInverse", LazySectionValueInverse],
["SectionValues", LazySectionValues],
["SectionVideo", LazySectionVideo],
["SectionCompare", LazySectionCompare],
["SectionTeam", LazySectionTeam],
["SectionTestimonials", LazySectionTestimonials],
["SectionHeadline", LazySectionHeadline],
["SectionTimeMachine", LazySectionTimeMachine],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
