import { UseFetchOptions } from 'nuxt/app'
import {
  ICryptocurrencyMarket,
  CoinGeckoGlobal,
  CoinMarket,
  IAddress,
  MarketChart,
  ICryptoPerformance,
  CurrencyRate,
  BlockchainFees
} from '~/types/feeder'
import { DEFAULT_CRYPTO, DEFAULT_FIAT } from '~/data/cryptoMarket'

export default function () {
  const config = useRuntimeConfig()
  const baseUrl = computed(() => config.public.feederUrl)
  const marketsBaseUrl = computed(() => `${config.public.feederUrl}/coingecko/markets`)
  const marketcapBaseUrl = computed(() => `${config.public.feederUrl}/coingecko/global`)

  // Markets
  const getMarkets = ({ opts }: { opts?: UseFetchOptions<CoinMarket[]> } = {}) => {
    return useFetch<CoinMarket[]>(`${marketsBaseUrl.value}`, opts)
  }

  const getMarketDetails = ({
    crypto = DEFAULT_CRYPTO,
    opts
  }: { crypto?: string; opts?: UseFetchOptions<ICryptocurrencyMarket> } = {}) => {
    return useFetch<ICryptocurrencyMarket>(`${marketsBaseUrl.value}/${crypto}`, opts)
  }

  const getMarketPerformance = ({
    crypto = DEFAULT_CRYPTO,
    opts
  }: { crypto?: string; opts?: UseFetchOptions<ICryptoPerformance> } = {}) => {
    return useFetch<ICryptoPerformance>(`${marketsBaseUrl.value}/${crypto}/range`, opts)
  }

  const getMarketHistory = ({
    crypto = DEFAULT_CRYPTO,
    fiat = DEFAULT_FIAT,
    days,
    marketCap,
    opts
  }: {
    crypto?: string
    fiat?: string
    days: number
    marketCap?: boolean
    opts?: UseFetchOptions<MarketChart>
  }) => {
    return useFetch<MarketChart>(`${marketsBaseUrl.value}/${crypto}/${fiat}/candles`, {
      ...opts,
      query: {
        days,
        ...(marketCap ? { marketcap: true } : {})
      }
    })
  }

  // Marketcap
  const getMarketcap = ({ opts }: { opts?: UseFetchOptions<CoinGeckoGlobal> } = {}) => {
    return useFetch<CoinGeckoGlobal>(`${marketcapBaseUrl.value}`, opts)
  }

  // Blockchain
  const getBalance = ({
    chain,
    address,
    opts
  }: {
    chain: string
    address: string
    opts?: UseFetchOptions<{ [key: string]: IAddress }>
  }) => {
    return useFetch<{ [key: string]: IAddress }>(
      `${baseUrl.value}/address/${chain}/${address}/balance`,
      opts
    )
  }

  // Blockchain Fees
  const getFees = ({ opts }: { opts?: UseFetchOptions<BlockchainFees> } = {}) => {
    return useFetch<BlockchainFees>(`${baseUrl.value}/blockchain/stats/fees`, opts)
  }

  // Currency Conversion
  const getConversion = ({ opts }: { opts?: UseFetchOptions<any> } = {}) => {
    return useFetch<any>(`${baseUrl.value}/fixer/latest`, {
      ...opts,
      transform: data => JSON.parse(data) as CurrencyRate
    })
  }

  return {
    getMarkets,
    getMarketDetails,
    getMarketPerformance,
    getMarketHistory,
    getMarketcap,
    getBalance,
    getFees,
    getConversion
  }
}
