export enum Asset {
  // Crypto Assets
  Bitcoin = 'btc',
  Ethereum = 'eth',
  Cardano = 'ada',
  Dogecoin = 'eth-doge',
  Solana = 'sol',
  Polygon = 'matic',
  Polkadot = 'dot',
  Litecoin = 'ltc',
  ShibaInu = 'eth-shib',
  TRON = 'trx',
  Uniswap = 'eht-uni',
  Cosmos = 'atom',
  Chainlink = 'eth-link',
  Monero = 'xmr',
  // Fiat Assets
  Euro = 'eur',
  Dollar = 'usd',
  SwissFrancs = 'chf',
  PoundSterling = 'gbp',
  Yuan = 'cny',
  // Raw Materials
  Gold = 'xau',
  Silver = 'xag',
  Oil = 'brentoil',
  Gas = 'ng',
  // Moments
  Holiday = 'holiday',
  Car = 'car',
  House = 'house',
  Wedding = 'wedding'
}

export const MOMENTS = [Asset.Holiday, Asset.Car, Asset.House, Asset.Wedding]
