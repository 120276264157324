export enum CountryCode {
  DE = 'de',
  CH = 'ch',
  AT = 'at'
}

export enum LanguageCode {
  DE = 'de',
  EN = 'en',
  FR = 'fr',
  IT = 'it'
}

// Locale code (lowercase language code + dash + lowercase country code). Example: de-de
export enum LocaleCode {
  DE_DE = `${LanguageCode.DE}-${CountryCode.DE}`,
  EN_DE = `${LanguageCode.EN}-${CountryCode.DE}`,
  DE_CH = `${LanguageCode.DE}-${CountryCode.CH}`,
  EN_CH = `${LanguageCode.EN}-${CountryCode.CH}`,
  FR_CH = `${LanguageCode.FR}-${CountryCode.CH}`,
  IT_CH = `${LanguageCode.IT}-${CountryCode.CH}`,
  DE_AT = `${LanguageCode.DE}-${CountryCode.AT}`,
  EN_AT = `${LanguageCode.EN}-${CountryCode.AT}`
}

export interface Locale {
  code: LocaleCode
  lang: LanguageCode
  file: string
  iso: string
  countryName: string
  languageName: string
}
