import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import { LocalStorageKey } from '~/types/localStorage'

import { DiscoverLevelRecord } from '~/types/generated'

export const useUserStore = defineStore('UserStore', {
  state: () => ({
    allLevels: [] as DiscoverLevelRecord[],
    userLevel: useLocalStorage(LocalStorageKey.UserLevel, {}) as unknown as DiscoverLevelRecord,
    geolocation: {},
    isLoading: false
  }),
  getters: {
    getLevel(state) {
      return (levelTitle: DiscoverLevelRecord['title']) =>
        state.allLevels.find(level => level.title === levelTitle)
    }
  },
  actions: {
    addLevels(allLevels: DiscoverLevelRecord[]) {
      this.allLevels = allLevels

      if (Object.keys(this.userLevel).length === 0) {
        this.userLevel = JSON.stringify(allLevels[0]) as unknown as DiscoverLevelRecord
      }
    },
    setLevel(level: DiscoverLevelRecord) {
      const { bodyLock, bodyUnlock } = useBodyLock()

      this.setLoading(true)
      bodyLock()
      this.userLevel = this.getLevel(
        level.title as DiscoverLevelRecord['title']
      ) as DiscoverLevelRecord

      useTimeoutFn(() => {
        this.setLoading(false)
        bodyUnlock()
      }, 1000)
    },
    setLoading(bool: boolean) {
      this.isLoading = bool
    },
    async fetchGeoIP() {
      const config = useRuntimeConfig()

      const { data } = await useFetch(
        `https://extreme-ip-lookup.com/json/?key=${config.public.extremeIpLookupApiKey}`,
        {
          pick: ['countryCode', 'lat', 'lon'] as unknown as []
        }
      )

      if (data.value) {
        this.geolocation = data.value
      }
    }
  },
  hydrate(storeState) {
    // in this case we can completely ignore the initial state and we read from the browser
    // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/43826
    storeState.userLevel = useLocalStorage(
      LocalStorageKey.UserLevel,
      JSON.stringify({ id: '166967000', title: 'Beginner', label: "I'm new to crypto" })
    )
  }
})
