import { useModalStore } from '~/stores/ModalStore'
import { getCountryCodeFromLocaleCode } from '~/i18n/locales'
import { CountryCode, LocaleCode } from '~/types/locales'

export default defineNuxtRouteMiddleware(to => {
  if (process.server) {
    return
  }

  const localePath = useLocalePath()
  const store = useModalStore()
  const { $i18n } = useNuxtApp()
  const countryCode = getCountryCodeFromLocaleCode($i18n.locale.value as LocaleCode)

  if (to.path.endsWith('/go')) {
    if (countryCode === CountryCode.CH) {
      setTimeout(() => {
        store.setShowVoucherActivation(true)
      }, 1000)
    }
    return navigateTo(localePath('/'))
  }
})
