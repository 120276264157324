<template>
  <div
    class="px-page min-h-screen pb-20 pt-10 md:flex md:flex-col md:justify-center md:pb-30 md:pt-20"
  >
    <div class="grid-default items-center gap-y-6 md:gap-y-0">
      <div class="col-span-12 md:order-2 md:col-span-6 md:col-start-7">
        <Picture loading="lazy" :data="imgData" alt="Rubik's cube" class="h-full w-full" />
      </div>
      <div class="col-span-12 md:col-span-4 md:col-start-2">
        <div class="text-body-15-regular-mobile text-neutral-800 md:text-body-20-regular">
          {{ error?.statusCode }}
        </div>
        <Heading :level="1" :size="1" class="mb-6 md:mb-8">{{ error?.statusMessage }}</Heading>
        <Type class="mb-8 md:mb-10" :level="2">{{ error?.message }}</Type>
        <Button size="lg" @click="resetError">{{ $t('app.safetyLink') }}</Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import errorImage from '~/assets/images/404@2x.jpg'
import errorImageWebp from '~/assets/images/404@2x.webp'
import { FileField } from '~/types/generated'

defineProps({
  error: Object
})

const imgData = {
  width: 1060,
  height: 706,
  responsiveImage: {
    srcSet: errorImage,
    webpSrcSet: errorImageWebp,
    alt: "Rubik's cube",
    sizes: '(max-width: 1060px) 100vw, 1060px'
  }
} as FileField

const localePath = useLocalePath()

// Error handling
const resetError = async (error: { value: null }) => {
  await navigateTo(localePath('/'))
  error.value = null
}
</script>
