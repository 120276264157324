import { ComposerTranslation } from '@nuxtjs/i18n/dist/runtime/composables'
import { Asset } from './timeMachine'

// Range filter
export enum DateTimeRange {
  ONE_YEAR = '1y',
  ONE_MONTH = '1m',
  ONE_WEEK = '1w',
  ONE_DAY = '1d'
}

export const rangeOptions = (t: ComposerTranslation) => [
  {
    value: DateTimeRange.ONE_YEAR,
    label: t('cryptoMarket.dateTimeRange.year')
  },
  {
    value: DateTimeRange.ONE_MONTH,
    label: t('cryptoMarket.dateTimeRange.month')
  },
  {
    value: DateTimeRange.ONE_WEEK,
    label: t('cryptoMarket.dateTimeRange.week')
  },
  {
    value: DateTimeRange.ONE_DAY,
    label: t('cryptoMarket.dateTimeRange.day')
  }
]

export const rangeDays = {
  [DateTimeRange.ONE_YEAR]: 365,
  [DateTimeRange.ONE_MONTH]: 30,
  [DateTimeRange.ONE_WEEK]: 7,
  [DateTimeRange.ONE_DAY]: 1
}

export const rangeDateTimeFormats = {
  [DateTimeRange.ONE_YEAR]: 'MMM',
  [DateTimeRange.ONE_MONTH]: 'D MMM',
  [DateTimeRange.ONE_WEEK]: 'ddd',
  [DateTimeRange.ONE_DAY]: 'HH:mm'
}

export const DEFAULT_DATE_TIME_RANGE = DateTimeRange.ONE_YEAR

// Assets filter
export enum Assets {
  ALL = 'all',
  CRYPTO = 'crypto',
  FIAT = 'fiat',
  RAW_MATERIALS = 'rawMaterials'
}

export const assetsOptions = (t: ComposerTranslation) => [
  {
    value: Assets.ALL,
    label: t('cryptoMarket.assets.all')
  },
  {
    value: Assets.CRYPTO,
    label: t('cryptoMarket.assets.crypto')
  },
  {
    value: Assets.FIAT,
    label: t('cryptoMarket.assets.fiat')
  },
  {
    value: Assets.RAW_MATERIALS,
    label: t('cryptoMarket.assets.rawMaterials')
  }
]

// Crypto Markets
export const MARKETS_POLLING_INTERVAL = 1000 * 60 // 60 seconds

export enum Crypto {
  // Crypto Assets
  Bitcoin = 'bitcoin',
  Ethereum = 'ethereum',
  BinanceCoin = 'binance-coin-wormhole',
  Cardano = 'cardano',
  Ripple = 'xrp',
  Polkadot = 'polkadot',
  Uniswap = 'uniswap',
  Litecoin = 'litecoin',
  Chainlink = 'chainlink',
  BitcoinCash = 'bitcoin-cash',
  Stellar = 'stellar',
  Dogecoin = 'dogecoin',
  WrappedBitcoin = 'wrapped-bitcoin',
  Aave = 'aave',
  Cosmos = 'cosmos-hub',
  Monero = 'monero',
  ShibaInu = 'shiba-inu',
  EOS = 'eos',
  TRON = 'tron',
  Tezos = 'tezos',
  IOTA = 'iota',
  VeChain = 'vechain',
  NEO = 'neo',
  ThetaNetwork = 'theta-network',
  Maker = 'maker',
  SynthetixNetworkToken = 'synthetix-network-token',
  Algorand = 'algorand',
  Compound = 'compound',
  OMGNetwork = 'omg-network',
  BasicAttentionToken = 'basic-attention-token',
  Zcash = 'zcash',
  '0x' = '0x',
  Digibyte = 'digibyte',
  KyberNetwork = 'kyber-network-crystal',
  Decentraland = 'decentraland',
  Cronos = 'cronos',
  YearnFinance = 'yearn-finance',
  PancakeSwap = 'pancakeswap',
  Arbitrum = 'arbitrum',
  SushiSwap = 'sushi',
  Terra = 'terra',
  Avalanche2 = 'avalanche-2',
  Kusama = 'kusama',
  TheGraph = 'the-graph',
  Multiversx = 'multiversx',
  Frax = 'frax',
  Dash = 'dash',
  Zilliqa = 'zilliqa',
  Loopring = 'loopring',
  Solana = 'solana',
  TheSandbox = 'the-sandbox',
  RocketPool = 'rocket-pool-eth',
  HederaHashgraph = 'hedera',
  Optimism = 'optimism',
  Qtum = 'qtum',
  NEM = 'nem',
  Ravencoin = 'ravencoin',
  EnjinCoin = 'enjin-coin',
  Ren = 'ren',
  Balancer = 'balancer',
  Waves = 'waves',
  CurveDAOToken = 'curve-dao-token',
  Aavegotchi = 'aavegotchi',
  Bancor = 'bancor-network',
  UMA = 'uma',
  '1Inch' = '1inch',
  THORChain = 'thorchain',
  Fantom = 'fantom',
  Nano = 'nano',
  BitTorrent = 'bittorrent',
  ICON = 'icon',
  OceanProtocol = 'ocean-protocol',
  Filecoin = 'filecoin',
  CompoundEther = 'compound-ether',
  CelsiusNetwork = 'celsius-network-token',
  Polygon = 'polygon',
  Ontology = 'ontology',
  Harmony = 'harmony',
  Siacoin = 'siacoin',
  Arweave = 'arweave'
}

export const ASSET_COLORS = {
  // Crypto Assets
  [Crypto.Bitcoin]: '#F7931A',
  [Asset.Bitcoin]: '#F7931A',
  [Crypto.Ethereum]: '#627EEA',
  [Asset.Ethereum]: '#627EEA',
  [Crypto.BinanceCoin]: '#F3BA2F',
  [Crypto.Cardano]: '#0D1E30',
  [Asset.Cardano]: '#0D1E30',
  [Crypto.Ripple]: '#23292F',
  [Crypto.Polkadot]: '#E6007A',
  [Asset.Polkadot]: '#E6007A',
  [Crypto.Uniswap]: '#FF007A',
  [Asset.Uniswap]: '#FF007A',
  [Crypto.Litecoin]: '#345D9D',
  [Asset.Litecoin]: '#345D9D',
  [Crypto.Chainlink]: '#2A5ADA',
  [Asset.Chainlink]: '#2A5ADA',
  [Crypto.BitcoinCash]: '#43A047',
  [Crypto.Stellar]: '#000000',
  [Crypto.Dogecoin]: '#C3A634',
  [Asset.Dogecoin]: '#C3A634',
  [Crypto.WrappedBitcoin]: '#201A2D',
  [Crypto.Aave]: '#B6509E',
  [Crypto.Cosmos]: '#2E3148',
  [Asset.Cosmos]: '#2E3148',
  [Crypto.Monero]: '#FF6600',
  [Asset.Monero]: '#FF6600',
  [Crypto.ShibaInu]: '#d6210bff',
  [Asset.ShibaInu]: '#d6210bff',
  [Crypto.EOS]: '#000000',
  [Crypto.TRON]: '#EF0027',
  [Asset.TRON]: ' #EF0027',
  [Crypto.Tezos]: '#2C7DF7',
  [Crypto.IOTA]: '#242424',
  [Crypto.VeChain]: '#15BDFF',
  [Crypto.NEO]: '#58BF00',
  [Crypto.ThetaNetwork]: '#2AB8E6',
  [Crypto.Maker]: '#1AAB9B',
  [Crypto.SynthetixNetworkToken]: '#00D1FF',
  [Crypto.Algorand]: '#000000',
  [Crypto.Compound]: '#00D294FF',
  [Crypto.OMGNetwork]: '#1A53F0',
  [Crypto.BasicAttentionToken]: '#FF5000',
  [Crypto.Zcash]: '#ECB244',
  [Crypto['0x']]: '#302C2C',
  [Crypto.Digibyte]: '#006AD2',
  [Crypto.KyberNetwork]: '#188C92',
  [Crypto.Decentraland]: '#FF2D55',
  [Crypto.Cronos]: '#2B3865FF',
  [Crypto.YearnFinance]: '#006AE3',
  [Crypto.PancakeSwap]: '#43D7E2FF',
  [Crypto.Arbitrum]: '#269FEFFF',
  [Crypto.SushiSwap]: '#FA52A0',
  [Crypto.Terra]: '#FFD83D',
  [Crypto.Avalanche2]: '#E84142',
  [Crypto.Kusama]: '#000000FF',
  [Crypto.TheGraph]: '#4260BEFF',
  [Crypto.Multiversx]: '#21EDD4FF',
  [Crypto.Frax]: '#000000FF',
  [Crypto.Dash]: '#008CE7',
  [Crypto.Zilliqa]: '#49C1BF',
  [Crypto.Loopring]: '#2AB6F6',
  [Crypto.Solana]: '#7765E9FF',
  [Asset.Solana]: '#7765E9FF',
  [Crypto.TheSandbox]: '#00AEEFFF',
  [Crypto.RocketPool]: '#FFAA7AFF',
  [Crypto.HederaHashgraph]: '#000000FF',
  [Crypto.Optimism]: '#FE0420FF',
  [Crypto.Qtum]: '#2E9AD0',
  [Crypto.NEM]: '#67B2E8',
  [Crypto.Ravencoin]: '#384182',
  [Crypto.EnjinCoin]: '#9D4BEF',
  [Crypto.Ren]: '#80817',
  [Crypto.Balancer]: '#1E1E1E',
  [Crypto.Waves]: '#0155FF',
  [Crypto.CurveDAOToken]: '#B7FD3DFF',
  [Crypto.Aavegotchi]: '#9F13FAFF',
  [Crypto.Bancor]: '#000D2B',
  [Crypto.UMA]: '#FF4A4A',
  [Crypto['1Inch']]: '#94A6C3',
  [Crypto.THORChain]: '#1FE0B5FF',
  [Crypto.Fantom]: '#1969FFFF',
  [Crypto.Nano]: '#4A90E2',
  [Crypto.BitTorrent]: '#000000',
  [Crypto.ICON]: '#1FC5C9',
  [Crypto.OceanProtocol]: '#000000',
  [Crypto.Filecoin]: '#2B61D1',
  [Crypto.CompoundEther]: '#A49DFDFF',
  [Crypto.CelsiusNetwork]: '#F5973CFF',
  [Crypto.Polygon]: '#2B6DEFFF',
  [Asset.Polygon]: '#2B6DEFFF',
  [Crypto.Ontology]: '#32A4BE',
  [Crypto.Harmony]: '#33C3F0FF',
  [Crypto.Siacoin]: '#20EE82',
  [Crypto.Arweave]: '#25262AFF',
  // Fiat Assets
  [Asset.Euro]: '#0563c1',
  [Asset.Dollar]: '#00a368',
  [Asset.SwissFrancs]: '#c8164c',
  [Asset.PoundSterling]: '#009eaa',
  [Asset.Yuan]: '#ff4500',
  // Raw Materials
  [Asset.Gold]: '#bea65e',
  [Asset.Silver]: '#bfbfbf',
  [Asset.Oil]: '#1cb6c2',
  [Asset.Gas]: '#9bd547',
  // Moments
  [Asset.Holiday]: '#0096EA',
  [Asset.Car]: '#ee3046',
  [Asset.House]: '#6a5cff',
  [Asset.Wedding]: '#E815C6'
}

export const CRYPTO_DATA = {
  [Crypto.Bitcoin]: {
    consensus: 'Proof-of-Work',
    createdYear: 2009
  },
  [Crypto.Ethereum]: {
    consensus: 'Proof-of-Stake',
    createdYear: 2015
  },
  [Crypto.BinanceCoin]: {
    consensus: 'Proof-of-Staked-Authority',
    createdYear: 2017
  },
  [Crypto.Cardano]: {
    consensus: 'Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.Ripple]: {
    consensus: 'Consensus',
    createdYear: 2012
  },
  [Crypto.Polkadot]: {
    consensus: 'Nominated Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Uniswap]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Litecoin]: {
    consensus: 'Proof-of-Work',
    createdYear: 2011
  },
  [Crypto.Chainlink]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.BitcoinCash]: {
    consensus: 'Proof-of-Work',
    createdYear: 2017
  },
  [Crypto.Stellar]: {
    consensus: 'Consensus',
    createdYear: 2014
  },
  [Crypto.Dogecoin]: {
    consensus: 'Proof-of-Work',
    createdYear: 2013
  },
  [Crypto.WrappedBitcoin]: {
    consensus: 'Proof-of-Reserve (Tokenized Bitcoin)',
    createdYear: 2019
  },
  [Crypto.Aave]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Cosmos]: {
    consensus: 'Tendermint BFT (variant of Proof-of-Stake)',
    createdYear: 2019
  },
  [Crypto.Monero]: {
    consensus: 'Proof-of-Work',
    createdYear: 2014
  },
  [Crypto.ShibaInu]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.EOS]: {
    consensus: 'Delegated Proof-of-Stake',
    createdYear: 2018
  },
  [Crypto.TRON]: {
    consensus: 'Delegated Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.Tezos]: {
    consensus: 'Liquid Proof-of-Stake',
    createdYear: 2018
  },
  [Crypto.IOTA]: {
    consensus: 'Tangle (a form of Directed Acyclic Graph)',
    createdYear: 2015
  },
  [Crypto.VeChain]: {
    consensus: 'Proof-of-Authority',
    createdYear: 2015
  },
  [Crypto.NEO]: {
    consensus: 'Delegated Byzantine Fault Tolerance',
    createdYear: 2014
  },
  [Crypto.ThetaNetwork]: {
    consensus: 'Multi-Level Byzantine Fault Tolerance',
    createdYear: 2018
  },
  [Crypto.Maker]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2015
  },
  [Crypto.SynthetixNetworkToken]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2018
  },
  [Crypto.Algorand]: {
    consensus: 'Pure Proof-of-Stake',
    createdYear: 2019
  },
  [Crypto.Compound]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.OMGNetwork]: {
    consensus: 'Proof-of-Authority',
    createdYear: 2017
  },
  [Crypto.BasicAttentionToken]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.Zcash]: {
    consensus: 'Proof-of-Work',
    createdYear: 2016
  },
  [Crypto['0x']]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.Digibyte]: {
    consensus: 'Multi-Algo Proof-of-Work',
    createdYear: 2013
  },
  [Crypto.KyberNetwork]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.Decentraland]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.Cronos]: {
    consensus: 'Proof-of-Authority',
    createdYear: 2021
  },
  [Crypto.YearnFinance]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.PancakeSwap]: {
    consensus: '(BSC based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Arbitrum]: {
    consensus: 'Optimistic L2',
    createdYear: 2021
  },
  [Crypto.SushiSwap]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Terra]: {
    consensus: 'Proof-of-Stake',
    createdYear: 2108
  },
  [Crypto.Avalanche2]: {
    consensus: 'Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Kusama]: {
    consensus: 'Nominated Proof-of-Stake',
    createdYear: 2019
  },
  [Crypto.TheGraph]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Multiversx]: {
    consensus: 'Secure Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Frax]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Dash]: {
    consensus: 'Proof-of-Work & Proof-of-Service',
    createdYear: 2014
  },
  [Crypto.Zilliqa]: {
    consensus: 'Sharded Proof-of-Work & Byzantine Fault Tolerance',
    createdYear: 2017
  },
  [Crypto.Loopring]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.Solana]: {
    consensus: 'Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.TheSandbox]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2021
  },
  [Crypto.RocketPool]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2021
  },
  [Crypto.HederaHashgraph]: {
    consensus: 'Hashgraph',
    createdYear: 2019
  },
  [Crypto.Optimism]: {
    consensus: 'Optimistic L2',
    createdYear: 2021
  },
  [Crypto.Qtum]: {
    consensus: 'Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.NEM]: {
    consensus: 'Proof-of-Importance',
    createdYear: 2015
  },
  [Crypto.Ravencoin]: {
    consensus: 'Proof-of-Work',
    createdYear: 2018
  },
  [Crypto.EnjinCoin]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.Ren]: {
    consensus: 'Byzantine Fault Tolerance',
    createdYear: 2018
  },
  [Crypto.Balancer]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Waves]: {
    consensus: 'Leased Proof-of-Stake',
    createdYear: 2016
  },
  [Crypto.CurveDAOToken]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Aavegotchi]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.Bancor]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.UMA]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto['1Inch']]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2020
  },
  [Crypto.THORChain]: {
    consensus: 'Proof-of-Stake',
    createdYear: 2018
  },
  [Crypto.Fantom]: {
    consensus: 'Lachesis consensus algorithm',
    createdYear: 2018
  },
  [Crypto.Nano]: {
    consensus: 'Open Representative Voting (ORV)',
    createdYear: 2015
  },
  [Crypto.BitTorrent]: {
    consensus: 'TRON based (Delegated Proof of Stake)',
    createdYear: 2019
  },
  [Crypto.ICON]: {
    consensus: 'Delegated Proof-of-Contribution',
    createdYear: 2017
  },
  [Crypto.OceanProtocol]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.Filecoin]: {
    consensus: 'Proof of Spacetime/Proof of Replication',
    createdYear: 2020
  },
  [Crypto.CompoundEther]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2018
  },
  [Crypto.CelsiusNetwork]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.Polygon]: {
    consensus: '(Ethereum based) Proof-of-Stake',
    createdYear: 2017
  },
  [Crypto.Ontology]: {
    consensus: 'VBFT Consensus Mechanism (mix of PoS, VRF, and BFT)',
    createdYear: 2017
  },
  [Crypto.Harmony]: {
    consensus: 'Effective Proof-of-Stake',
    createdYear: 2019
  },
  [Crypto.Siacoin]: {
    consensus: 'Proof-of-Work',
    createdYear: 2015
  },
  [Crypto.Arweave]: {
    consensus: 'Proof-of-Access and Proof-of-Work',
    createdYear: 2017
  }
}

export const DEFAULT_CRYPTO = Crypto.Bitcoin
export const DEFAULT_SECONDARY_CRYPTO = Crypto.Ethereum
export const DEFAULT_FIAT = Asset.Euro

export const WALLET_BALANCE_OPTIONS = [
  Crypto.Bitcoin,
  Crypto.Ethereum,
  Crypto.Avalanche2,
  Crypto.Polkadot,
  Crypto.Solana
]

export const COMPARE_ASSETS_OPTIONS = [
  Crypto.Bitcoin,
  Crypto.Ethereum,
  Crypto.BinanceCoin,
  Crypto.Cardano,
  Crypto.Ripple,
  Crypto.Polkadot,
  Crypto.Uniswap,
  Crypto.Litecoin,
  Crypto.Chainlink,
  Crypto.BitcoinCash,
  Crypto.Stellar,
  Crypto.Dogecoin,
  Crypto.WrappedBitcoin,
  Crypto.Aave,
  Crypto.Cosmos,
  Crypto.Monero,
  Crypto.ShibaInu,
  Crypto.EOS,
  Crypto.TRON,
  Crypto.Tezos,
  Crypto.IOTA,
  Crypto.VeChain,
  Crypto.NEO,
  Crypto.ThetaNetwork,
  Crypto.Maker,
  Crypto.SynthetixNetworkToken,
  Crypto.Algorand,
  Crypto.Compound,
  Crypto.OMGNetwork,
  Crypto.BasicAttentionToken,
  Crypto.Zcash,
  Crypto['0x'],
  Crypto.Digibyte,
  Crypto.KyberNetwork,
  Crypto.Decentraland,
  Crypto.Cronos,
  Crypto.YearnFinance,
  Crypto.PancakeSwap,
  Crypto.Arbitrum,
  Crypto.SushiSwap,
  Crypto.Terra,
  Crypto.Avalanche2,
  Crypto.Kusama,
  Crypto.TheGraph,
  Crypto.Multiversx,
  Crypto.Frax,
  Crypto.Dash,
  Crypto.Zilliqa,
  Crypto.Loopring,
  Crypto.Solana,
  Crypto.TheSandbox,
  Crypto.RocketPool,
  Crypto.HederaHashgraph,
  Crypto.Optimism,
  Crypto.Qtum,
  Crypto.NEM,
  Crypto.Ravencoin,
  Crypto.EnjinCoin,
  Crypto.Ren,
  Crypto.Balancer,
  Crypto.Waves,
  Crypto.CurveDAOToken,
  Crypto.Aavegotchi,
  Crypto.Bancor,
  Crypto.UMA,
  Crypto['1Inch'],
  Crypto.THORChain,
  Crypto.Fantom,
  Crypto.Nano,
  Crypto.BitTorrent,
  Crypto.ICON,
  Crypto.OceanProtocol,
  Crypto.Filecoin,
  Crypto.CompoundEther,
  Crypto.CelsiusNetwork,
  Crypto.Polygon,
  Crypto.Ontology,
  Crypto.Harmony,
  Crypto.Siacoin,
  Crypto.Arweave
]

const MOCK_LINE_DATA = [
  19925.888669035365, 20027.178609903058, 19896.975210012286, 19870.360513243373,
  19701.815407432718, 19470.860310803437, 19954.31339750781, 20380.697786538993, 20345.625862871297,
  20383.142392079746, 19960.494418039492, 19926.14978930852, 19982.428551133922, 19714.1042061995,
  19642.35569933938, 19728.339635858425, 19837.87640241695, 19748.09451844033, 19619.17752950558,
  19786.06339196892, 19873.188383011733, 19617.08306521306, 19592.81392416176, 19465.45094023754,
  19444.333134846896, 19480.78521127148, 19835.81536332392, 19534.217571069006, 20172.35780554517,
  20583.41525892392, 20338.775485057427, 20663.49587349789, 20873.696768895566, 20717.81323171006,
  20731.682756632563, 20741.648361813386, 20532.971604426093, 20731.020037253325,
  21187.392087131007, 21318.102034853226, 21050.378118738176, 20556.847875596974,
  18418.959036503136, 15714.170905933977, 17260.25046037946, 16468.41903014687, 16211.922497290752,
  15822.33671111262, 16131.921836994286, 16309.224924589491, 16058.103611925184, 16134.305278552902,
  16150.62879937794, 16154.591210551409, 15782.884430779812, 15439.915078745722, 15689.213114262966,
  15946.247219563651, 15944.641354952635, 15868.296186522324, 15801.201051209351, 15872.19981187594,
  15683.633332537333, 15928.168104093502, 16486.01510023392, 16125.353340056305, 16214.672401747104,
  16060.154956643215, 16270.140252138515, 16168.580116788793, 16323.913913085, 16026.95455424984,
  16324.729650223697, 16260.725867721216, 16245.669182083433, 16256.622964394597,
  16298.351479827777, 16733.670176064472, 16681.157308186917, 16301.368265436931,
  15686.011647171525, 15863.406459548603, 15818.144385123747, 15474.980430111103,
  15905.786443922567, 15851.869002108311, 15868.5027391886, 15741.468939098468, 15794.808675598817,
  15865.953932141418, 15879.536627937374, 15701.182858126174, 15561.125965724645,
  15613.542757040377, 15479.994747226247, 15420.408986264278, 15392.28625758783, 15620.007183388236,
  15815.912207427446, 15885.867349890046, 15997.614016945103, 15904.457616586064,
  15896.145016241107, 16019.60905965557, 16018.553997497338, 16238.359411241356, 16717.905645142466,
  17373.5022929487, 18385.444222575272, 19384.143550724402, 19265.861805310084, 19553.4974440394,
  19601.527587603123, 19195.96019998465, 19454.86171443394, 20875.743484379782, 20934.289777667287,
  20927.755382305437, 21136.385361082204, 20765.463323655134, 21222.6273772345, 21132.342292215766,
  21232.444122778525, 21174.744838263305, 21889.97541685201, 21051.755242194755, 21300.173789246634,
  21546.480188402475, 21585.50696344187, 21703.262536909377, 21588.656349144607, 21274.649601340945,
  21240.989796479484, 21711.931086292552, 21412.273659821534, 20319.391307470898, 20236.82796369696,
  20479.42431261332, 20396.090083595962, 20326.820633458083, 20695.77412705394, 22740.01715757629,
  22275.545328034692, 22982.215229088564, 22995.198515911703, 22724.807403926105,
  23194.122654146388, 22922.84761059098, 22766.658739690134, 22596.244538077084, 21892.22625737223,
  21903.084493940565, 22305.583929986267, 22166.98416973649, 21891.505769230393, 22152.12989868691,
  22136.020450218573, 21019.05705757611, 21001.105543098805, 21096.729932841485, 20976.2650987654,
  21059.471077802908, 20582.333998060043, 19251.60827197873, 18951.081675562727, 19275.40517599222,
  20672.042392654155, 22547.33524348763, 23080.120813008492, 23117.495451546903, 23702.3963046722,
  25545.38371563334, 25156.871487849796, 26391.312588562152, 26059.309953406624, 26238.17412252129,
  25254.00067571353, 26269.160842127236, 25607.23935175509, 25676.56162463012, 26031.336211558613,
  25150.72167313928, 25174.905826917286, 26182.573133157006, 25716.289647764308, 26240.43238864122,
  26224.016377715503, 26131.222883873223, 25497.92863894404, 25705.762630421377, 25856.80451572184,
  25683.446764349228, 25403.578565464828, 25438.298992686752, 25983.37003136075, 27286.374235018542,
  27720.98443706539, 27184.35728043426, 27514.999061673974, 27440.36683869996, 27302.103580685292,
  27587.678473010194, 26969.090715096343, 27667.498825501156, 26330.58072176761, 25760.75196719891,
  24602.49210186131, 25108.49264131508, 25111.716649219055, 24881.165659682803, 25823.848897621774,
  25668.88322963279, 26724.601161447234, 26367.85350429759, 26257.406648322805, 26667.324142563688,
  25637.378695903353, 26020.73648178248, 26183.671951541124, 26168.32713032945, 26351.3156733965,
  25768.153890017304, 25962.2202079898, 25184.941549977193, 25178.134464739906, 25166.778964057983,
  24761.95507001978, 24486.627790908973, 24501.15167624149, 24800.87017938824, 25038.324870070548,
  24873.083363648893, 25273.329292980983, 24911.414162055546, 24850.568388931923, 25046.25912037498,
  24744.64164949735, 24857.549584048968, 25278.04940517331, 24487.08378548421, 24690.03995437158,
  24897.15670179585, 25018.5323877093, 26214.04794171125, 25923.106305142344, 25824.20797930768,
  25477.81532735336, 24926.805976354495, 25433.040592031997, 25263.750998046344, 25521.423620836627,
  24081.895035432597, 25444.813891435933, 24606.575671577593, 24580.652303490406, 24625.15476268371,
  24046.2442211302, 24105.088711362536, 24074.22488714253, 23980.71531679903, 23159.869978819468,
  23354.974592817824, 24028.950138510558, 24187.503262426646, 24069.368230351833,
  24515.190251651085, 25944.637097299925, 27382.7928104035, 27319.76676636551, 28057.919289873273,
  27974.166955638662, 27926.06804807289, 27759.724227232306, 28012.74132378263, 27553.125890687483,
  28034.61327717197, 27921.919876535274, 28016.124734302964, 28023.169991380317, 28529.048991365,
  28277.32041548689, 28083.32366004953, 27536.808659740153, 27637.214856707884, 27588.146223397507,
  27507.98296936987, 27621.562848797457, 27803.086919971098, 27296.798440233542, 28015.506305847473,
  26959.339652551316, 26963.89173334154, 26929.82391967297, 26824.60116695984, 26585.134928571853,
  26702.949735395698, 26750.16328521134, 26870.86376074843, 26697.367863146646, 27017.62445457327,
  26382.304967248678, 26444.746432272936, 26504.12098550807, 26592.808213196047, 26573.22928140469,
  26611.89183167345, 26554.19257602209, 26581.63243094954, 26818.98675957616, 26632.47657275687,
  26640.71393359157, 26381.392710918888, 26344.082814898546, 26398.306401166836, 26515.029028411922,
  27173.998922824794, 26951.521659338174, 26784.237544844178, 26830.50314351549, 26844.462254012917,
  26759.28750078193, 26955.104794992014, 26752.34425461549, 26436.98254577218, 24370.96565976724,
  23915.555056676592, 23972.435893119782, 24058.688141085793, 23960.37459214116, 23998.857303131717,
  24347.65613909474, 24197.891332374093, 24126.04920669219, 24087.123271833065, 24157.708319597277,
  24118.777420361883, 25507.7652085861, 24976.31178467358, 23909.90097889167, 23928.004556001928,
  23966.3140395979, 24062.365174636532, 23930.82897403587, 24039.066568673057, 24015.74110254664,
  24487.081564929285, 24196.05571878432, 24179.335092587848, 24101.51896181105, 23382.8690784066,
  24047.050947598244, 24429.1236856199, 24945.95896141005, 24960.830907496485, 24888.798834083675,
  24862.153481423677, 25008.85510410949, 25482.487979657606, 25459.149576600183, 24915.0072083747,
  24917.92873910841, 24919.696743116932, 24652.486992014517, 24825.253670430586, 24793.08109466004,
  25078.567856695383, 25686.497373503666
]
const getLineGraphData = (days: number) => {
  const dateOffset = days * 24 * 60 * 60 * 1000
  const date = new Date()
  date.setTime(date.getTime() - dateOffset)
  return MOCK_LINE_DATA.map((value, index) => [
    date.getTime() + days * 24 * 60 * 60 * 1000 * (index / MOCK_LINE_DATA.length),
    value
  ]) as [number, number][]
}

export const MOCK_RANGE_LINE_DATA = {
  [DateTimeRange.ONE_DAY]: getLineGraphData(rangeDays[DateTimeRange.ONE_DAY]),
  [DateTimeRange.ONE_WEEK]: getLineGraphData(rangeDays[DateTimeRange.ONE_WEEK]),
  [DateTimeRange.ONE_MONTH]: getLineGraphData(rangeDays[DateTimeRange.ONE_MONTH]),
  [DateTimeRange.ONE_YEAR]: getLineGraphData(rangeDays[DateTimeRange.ONE_YEAR])
}
