<template>
  <svg
    aria-hidden="true"
    class="inline-block self-center overflow-hidden fill-current stroke-current stroke-0"
    :class="[classSize]"
  >
    <use :xlink:href="`${symbolDefs}#icon-${name}`" :fill="color" />
  </svg>
</template>

<script setup lang="ts">
import symbolDefs from '~/assets/images/icons/symbol-defs.svg'

export type IconName =
  // General
  | 'arrow-narrow-up-general'
  | 'arrow-narrow-down-general'
  | 'arrow-narrow-left-general'
  | 'calendar-general'
  | 'check-general'
  | 'checkbox-general'
  | 'chevron-down-general'
  | 'chevron-right-general'
  | 'circle-chevron-right-general'
  | 'circle-minus-general'
  | 'circle-plus-general'
  | 'clock-general'
  | 'close-general'
  | 'info-general'
  | 'link-general'
  | 'location-general'
  | 'minus-general'
  | 'pin-general'
  | 'plus-general'
  | 'qr-code-general'
  | 'search-general'
  | 'share-general'
  | 'target-general'
  | 'file-general'
  | 'upload-general'
  | 'heart'
  | 'star-filled'
  // Nav
  | 'activate-wallet-btc-nav-nav'
  | 'bank-note-nav'
  | 'building-nav'
  | 'chat-nav'
  | 'clock-rewind-nav'
  | 'credit-card-search-nav'
  | 'cryptocurrency-03-nav'
  | 'cryptocurrency-nav'
  | 'cryptonow-nav'
  | 'currency-btc-circle-nav'
  | 'folder-nav'
  | 'lock-nav'
  | 'phone-nav'
  | 'pin-nav'
  | 'puzzle-nav'
  | 'receipt-nav'
  | 'route-nav'
  | 'scales-nav'
  | 'security-nav'
  | 'ticket-nav'
  | 'users-nav'
  | 'wallet-02-nav'
  | 'wallet-nav'
  | 'trade-crypto-nav'
  // Topics
  | 'activate-wallet-btc-topics'
  | 'atom-topics'
  | 'blockchain-topics'
  | 'credit-card-topics'
  | 'cryptocurrency-topics'
  | 'currency-btc-circle-topics'
  | 'file-topics'
  | 'globe-topics'
  | 'glossary-topics'
  | 'graduation-hat-topics'
  | 'growth-topics'
  | 'layers-topics'
  | 'lightbulb-topics'
  | 'lock-topics'
  | 'phone-topics'
  | 'rocket-topics'
  | 'shield-topics'
  | 'ticket-topics'
  | 'user-topics'
  | 'users-topics'
  // Social
  | 'facebook'
  | 'linkedin'
  | 'twitter'
  | 'google'
  | 'star'
  | 'instagram'
  | 'f'
  | 'x'
  | 'in'

export type IconSize = 'nav' | 'topics'

interface IconProps {
  color?: string
  name: IconName
  size?: IconSize
  className?: string
}

const props = withDefaults(defineProps<IconProps>(), {
  color: 'black'
})

const classSize = computed(() => {
  let classList
  switch (props.size) {
    case 'nav':
      classList = 'h-6 w-6 md:h-6px md:w-6px lg:h-6 lg:w-6'
      break
    case 'topics':
      classList = 'h-6 w-6 md:h-9 md:w-9'
      break
    default:
      classList = ''
  }
  return classList
})
</script>
