export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"format-detection","content":"telephone=no"},{"name":"msapplication-Config","content":"/browserconfig.xml"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/images/icon.svg"},{"rel":"icon","type":"image/png","sizes":"48x48","href":"/images/icon-48x48.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/images/icon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/images/icon-16x16.png"},{"rel":"mask-icon","href":"/images/icon.svg","color":"#000000"},{"rel":"apple-touch-icon","href":"/images/apple-touch-icon.png"},{"rel":"manifest","href":"/manifest.json"},{"rel":"preconnect","href":"https://www.datocms-assets.com"},{"rel":"preconnect","href":"https://www.googletagmanager.com"}],"style":[],"script":[],"noscript":[],"titleTemplate":"%s"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appCdnURL = "/netlify/66ce21f974b234000860fbbb--cryptonow-website-ch.netlify.app"

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'