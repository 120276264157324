<template>
  <div ref="pictureRef">
    <picture
      class="block transform-gpu will-change-auto"
      :class="[
        {
          'overflow-hidden': clipType || clipSection,
          'rounded-t-4xl md:rounded-t-5xl': clipType === 'rounded' && clipDirection === 'top',
          'rounded-b-4xl md:rounded-b-5xl': clipType === 'rounded' && clipDirection === 'bottom',
          'rounded-l-5xl': clipType === 'rounded' && clipDirection === 'left',
          'rounded-l-full': clipType === 'logo' && clipDirection === 'left',
          'rounded-r-full': clipType === 'logo' && clipDirection === 'right',
          'rounded-r-full md:rounded-l-full md:rounded-r-none': clipSection === 'hero',
          'rounded-l-none rounded-r-full md:rounded-r-full': clipSection === 'heroLeft',
          [$style.circle]: clipEffect === 'circle',
          [$style.circleVisible]: clipEffect === 'circle' && isVisible
        },
        pictureClass
      ]"
    >
      <source
        v-if="data.responsiveImage"
        :srcset="data.responsiveImage.webpSrcSet"
        type="image/webp"
      />
      <img
        ref="imgRef"
        class="transform-gpu will-change-auto"
        :class="[
          {
            'w-full': clipType,
            'opacity-0 transition-opacity duration-400 ease-sine-out':
              clipEffect === 'image-fade' && loading === 'lazy',
            '!opacity-100': clipEffect === 'image-fade' && isVisible && loading === 'lazy',
            'scale-75 transition-transform duration-1000 ease-quart-out':
              clipEffect === 'circle' && loading === 'lazy',
            '!scale-100': clipEffect === 'circle' && isVisible && loading === 'lazy'
          },
          imgClass
        ]"
        :src="data.url"
        :srcset="data.responsiveImage ? data.responsiveImage.srcSet : undefined"
        :sizes="sizes ? sizes : data.responsiveImage ? data.responsiveImage.sizes : undefined"
        :loading="loading && loading"
        :width="width ? width : data.width"
        :height="height ? height : data.height"
        :alt="(data.alt as string) || ''"
        @load="onLoad($event)"
      />
    </picture>
  </div>
</template>

<script setup lang="ts">
import { UseIntersectionObserverOptions, useIntersectionObserver } from '@vueuse/core'
import { FileField } from '~/types/generated'

interface Props {
  data: FileField
  preload?: boolean
  loading?: 'lazy' | 'eager'
  alt?: string
  imgClass?: string | Array<string>
  pictureClass?: string
  width?: number
  height?: number
  sizes?: string
  /**
   * Determines the clip style.
   */
  clipType?: 'rounded' | 'logo'
  /**
   * Direction of the clip radius.
   */
  clipDirection?: 'left' | 'right' | 'top' | 'bottom'
  /**
   * Section specific clip. (Not to be used with `clipType` and `clipDirection`!)
   */
  clipSection?: 'hero' | 'heroLeft'
  clipEffect?: 'image-fade' | 'none' | 'circle'
  observerOptions?: UseIntersectionObserverOptions
}

const props = withDefaults(defineProps<Props>(), {
  clipEffect: 'image-fade',
  observerOptions: () => ({
    threshold: 0.75
  })
})

if (props.preload) {
  // prettier-ignore
  useHead({
    link: [
      {
        rel: 'preload',
        fetchpriority: 'high',
        as: 'image',
        imagesrcset: props.data.responsiveImage
          ? props.data.responsiveImage.webpSrcSet
          : props.data.url,
        imagesizes: props.sizes
          ? props.sizes
          : props.data.responsiveImage
            ? props.data.responsiveImage.sizes
            : undefined,
        type: props.data.mimeType
      }
    ]
  })
}

const pictureRef = ref<HTMLElement | null>(null)
const imgRef = ref<HTMLElement | null>(null)
const isLoaded = ref(false)
const isVisible = ref(false)

defineExpose({
  pictureRef,
  imgRef
})

const emit = defineEmits(['load'])

function onLoad(e: Event) {
  isLoaded.value = true
  emit('load', e)
}

const { stop } = useIntersectionObserver(
  pictureRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isVisible.value = true
    }
  },
  props.observerOptions
)

onUnmounted(() => {
  stop()
})
</script>

<style lang="postcss" module>
/* clipCircle */
.circle {
  clip-path: circle(0 at 50% 50%);
  transition: 800ms var(--ease-out-sine);
}

.circleVisible {
  clip-path: circle(100% at 50% 50%);
}
</style>
