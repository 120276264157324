<template>
  <div v-if="data">
    <NuxtLayout :data="data">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm'
import { useUserStore } from '~/stores/UserStore'
import { useAppStore } from '~/stores/AppStore'
import { useCryptoMarketStore } from '~/stores/CryptoMarketStore'
import { DiscoverLevelRecord, MenuItemRecord } from '~/types/generated'

const { localeDato } = useLanguage()
const store = useUserStore()
const appStore = useAppStore()
const cryptoMarketStore = useCryptoMarketStore()
const gtm = useGtm()
const route = useRoute()

const { data } = await useAsyncGql('app', {
  // @ts-ignore
  locale: localeDato.value,
  options: {
    initialCache: false
  }
})

if (data.value) {
  store.addLevels(data.value.allDiscoverLevels as DiscoverLevelRecord[])
  appStore.setMenuItems(data.value.allMenuItems as MenuItemRecord[])
}

onNuxtReady(() => {
  cryptoMarketStore.fetchCoins()

  setTimeout(() => {
    gtm?.enable(true)
    gtm?.trackView(route.name as string, route.path)
  }, 3000)
})
</script>
