import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["app","articles","article","SectionArticles","SectionFeaturedArticles","cryptoAsset","cryptoAssetCta","discoverCategories","discoverCategory","SectionDiscoverCategories","SectionDiscoverMore","SectionTopStories","SectionEducational","Page","Image","CallToAction","Button","Link","Anchor","Table","VideoEmbed","glossaries","helpArticles","helpArticle","SectionRelatedHelpArticles","SectionHelpQuestions","SectionHelpLinks","helpTopicsSection","helpTopic","SectionHero","SectionHeroFullscreen","SectionHeroRocket","SectionHeroBasic","SectionPage","SectionGlossary","SectionForm","SectionHeroContact","SectionSearch","SectionSearchTopic","SectionDiscoverHero","SectionThankYou","SectionStoreFinder","SectionTimeMachine","SectionWalletBalance","SectionCryptoMarket","index","pages","page","SectionCta","SectionCustomers","SectionAwards","SectionCompare","SectionNumber","SectionPress","SectionRegulation","SectionTabsScroll","SectionTeam","SectionValue","SectionValueInverse","SectionValues","SectionFeatures","SectionJobs","SectionLogos","SectionTable","SectionTestimonials","SectionFaq","SectionPartner","SectionBenefits","SectionMediaFullscreen","SectionGraph","SectionHeadline","SectionTeaser","AppStoreLink","SectionMobileApp","SectionCards","SectionImmersiveTitle","SectionAppSlides","SectionStickyScroll","SectionNewsletter","SectionCardLayers","voucherActivation"]}
export const GqlApp = (...params) => useGql()('app', ...params)
export const GqlArticle = (...params) => useGql()('article', ...params)
export const GqlArticles = (...params) => useGql()('articles', ...params)
export const GqlCryptoAsset = (...params) => useGql()('cryptoAsset', ...params)
export const GqlCryptoAssetCta = (...params) => useGql()('cryptoAssetCta', ...params)
export const GqlDiscoverCategories = (...params) => useGql()('discoverCategories', ...params)
export const GqlDiscoverCategory = (...params) => useGql()('discoverCategory', ...params)
export const GqlGlossaries = (...params) => useGql()('glossaries', ...params)
export const GqlHelpArticle = (...params) => useGql()('helpArticle', ...params)
export const GqlHelpArticles = (...params) => useGql()('helpArticles', ...params)
export const GqlHelpTopic = (...params) => useGql()('helpTopic', ...params)
export const GqlHelpTopicsSection = (...params) => useGql()('helpTopicsSection', ...params)
export const GqlIndex = (...params) => useGql()('index', ...params)
export const GqlPage = (...params) => useGql()('page', ...params)
export const GqlPages = (...params) => useGql()('pages', ...params)
export const GqlVoucherActivation = (...params) => useGql()('voucherActivation', ...params)