import { CountryCode, LanguageCode, Locale, LocaleCode } from '../types/locales'

export const getLanguageCodeFromLocaleCode = (locale: LocaleCode) => {
  return locale.split('-')[0] as LanguageCode
}

export const getCountryCodeFromLocaleCode = (locale: LocaleCode) => {
  return locale.split('-')[1] as CountryCode
}

export const getLocaleCodeFromLanguageAndCountryCode = (
  languageCode: LanguageCode,
  countryCode: CountryCode
) => {
  return `${languageCode}-${countryCode}` as LocaleCode
}

// Converts to a DatoCMS GraphQL friendly locale code (lowercase language code + underscore + uppercase country code). Example: de-de to de_DE
export const convertToDatoCMSGraphQLLocaleCode = (locale: LocaleCode) => {
  const arr = locale.split('-')
  return arr
    .map((i: string, idx: number) => (idx === 1 ? i.toUpperCase() : i))
    .splice(0, 2)
    .join('_')
}

// Converts from a DatoCMS Rest friendly locale code (lowercase language code + dash + uppercase country code). Example: de-DE to de-de
export const convertFromDatoCMSRestLocaleCode = (locale: string) => {
  const arr = locale.split('-')
  return arr
    .map((i: string, idx: number) => (idx === 1 ? i.toLowerCase() : i))
    .splice(0, 2)
    .join('-') as LocaleCode
}

const locales: Array<Locale> = [
  /*
  {
    code: LocaleCode.DE_DE,
    lang: LanguageCode.DE,
    file: 'de-de.json',
    iso: 'de-DE',
    countryName: 'Deutschland',
    languageName: 'Deutsch'
  },
  {
    code: LocaleCode.EN_DE,
    lang: LanguageCode.EN,
    file: 'en-de.json',
    iso: 'en-DE',
    countryName: 'Germany',
    languageName: 'English'
  },
  */
  {
    code: LocaleCode.DE_CH,
    lang: LanguageCode.DE,
    file: 'de-ch.json',
    iso: 'de-CH',
    countryName: 'Schweiz',
    languageName: 'Deutsch'
  },
  {
    code: LocaleCode.EN_CH,
    lang: LanguageCode.EN,
    file: 'en-ch.json',
    iso: 'en-CH',
    countryName: 'Switzerland',
    languageName: 'English'
  },
  {
    code: LocaleCode.FR_CH,
    lang: LanguageCode.FR,
    file: 'fr-ch.json',
    iso: 'fr-CH',
    countryName: 'Suisse',
    languageName: 'Francais'
  },
  {
    code: LocaleCode.IT_CH,
    lang: LanguageCode.IT,
    file: 'it-ch.json',
    iso: 'it-CH',
    countryName: 'Svizzera',
    languageName: 'Italiano'
  }
]

export default locales
