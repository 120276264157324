import { defineStore } from 'pinia'
import { MenuItemRecord } from '~/types/generated'

export const useAppStore = defineStore('AppStore', {
  state: () => ({
    isStickyHeaderPinEnabled: false, // Controls the StickyHeader toggle pin state!
    allMenuItems: [] as MenuItemRecord[],
    mainMenuHeight: 0,
    isLoading: true
  }),
  getters: {
    getMenuItemTitle(state) {
      return (slug: string) => {
        return state.allMenuItems.find(menuItem => menuItem.reference?.slug === slug)?.title
      }
    }
  },
  actions: {
    setMenuItems(menuItems: MenuItemRecord[]) {
      this.allMenuItems = menuItems
      this.isLoading = false
    },
    setMenuHeight(height: number) {
      this.mainMenuHeight = height
    },
    setStickyHeader(enabled: boolean) {
      this.isStickyHeaderPinEnabled = enabled
    }
  }
})
