import { default as _91slug_93RPYB4NvKKjMeta } from "/opt/build/repo/pages/[slug].vue?macro=true";
import { default as _91slug_93U2bwZE017VMeta } from "/opt/build/repo/pages/discover/[category]/[slug].vue?macro=true";
import { default as _91slug_93ZwkuUpB03oMeta } from "/opt/build/repo/pages/discover/[slug].vue?macro=true";
import { default as _91slug_93k0fHvPvUKoMeta } from "/opt/build/repo/pages/globaler-kryptomarkt/[slug].vue?macro=true";
import { default as _91slug_93nTV3Zm82ayMeta } from "/opt/build/repo/pages/hilfe/[slug].vue?macro=true";
import { default as _91slug_93Kv8X78D3VEMeta } from "/opt/build/repo/pages/hilfe/[topic]/[category]/[slug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
export default [
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___de-ch",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/de-ch/:slug",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___en-ch",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/en-ch/:slug",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___fr-ch",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/fr-ch/:slug",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___it-ch",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/it-ch/:slug",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U2bwZE017VMeta?.name ?? "discover-category-slug___de-ch",
    path: _91slug_93U2bwZE017VMeta?.path ?? "/de-ch/discover/:category/:slug",
    meta: _91slug_93U2bwZE017VMeta || {},
    alias: _91slug_93U2bwZE017VMeta?.alias || [],
    redirect: _91slug_93U2bwZE017VMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/discover/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U2bwZE017VMeta?.name ?? "discover-category-slug___en-ch",
    path: _91slug_93U2bwZE017VMeta?.path ?? "/en-ch/discover/:category/:slug",
    meta: _91slug_93U2bwZE017VMeta || {},
    alias: _91slug_93U2bwZE017VMeta?.alias || [],
    redirect: _91slug_93U2bwZE017VMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/discover/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U2bwZE017VMeta?.name ?? "discover-category-slug___fr-ch",
    path: _91slug_93U2bwZE017VMeta?.path ?? "/fr-ch/decouvrir/:category/:slug",
    meta: _91slug_93U2bwZE017VMeta || {},
    alias: _91slug_93U2bwZE017VMeta?.alias || [],
    redirect: _91slug_93U2bwZE017VMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/discover/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93U2bwZE017VMeta?.name ?? "discover-category-slug___it-ch",
    path: _91slug_93U2bwZE017VMeta?.path ?? "/it-ch/scoprire/:category/:slug",
    meta: _91slug_93U2bwZE017VMeta || {},
    alias: _91slug_93U2bwZE017VMeta?.alias || [],
    redirect: _91slug_93U2bwZE017VMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/discover/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZwkuUpB03oMeta?.name ?? "discover-slug___de-ch",
    path: _91slug_93ZwkuUpB03oMeta?.path ?? "/de-ch/discover/:slug",
    meta: _91slug_93ZwkuUpB03oMeta || {},
    alias: _91slug_93ZwkuUpB03oMeta?.alias || [],
    redirect: _91slug_93ZwkuUpB03oMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/discover/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZwkuUpB03oMeta?.name ?? "discover-slug___en-ch",
    path: _91slug_93ZwkuUpB03oMeta?.path ?? "/en-ch/discover/:slug",
    meta: _91slug_93ZwkuUpB03oMeta || {},
    alias: _91slug_93ZwkuUpB03oMeta?.alias || [],
    redirect: _91slug_93ZwkuUpB03oMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/discover/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZwkuUpB03oMeta?.name ?? "discover-slug___fr-ch",
    path: _91slug_93ZwkuUpB03oMeta?.path ?? "/fr-ch/decouvrir/:slug",
    meta: _91slug_93ZwkuUpB03oMeta || {},
    alias: _91slug_93ZwkuUpB03oMeta?.alias || [],
    redirect: _91slug_93ZwkuUpB03oMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/discover/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZwkuUpB03oMeta?.name ?? "discover-slug___it-ch",
    path: _91slug_93ZwkuUpB03oMeta?.path ?? "/it-ch/scoprire/:slug",
    meta: _91slug_93ZwkuUpB03oMeta || {},
    alias: _91slug_93ZwkuUpB03oMeta?.alias || [],
    redirect: _91slug_93ZwkuUpB03oMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/discover/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0fHvPvUKoMeta?.name ?? "globaler-kryptomarkt-slug___de-ch",
    path: _91slug_93k0fHvPvUKoMeta?.path ?? "/de-ch/globaler-kryptomarkt/:slug",
    meta: _91slug_93k0fHvPvUKoMeta || {},
    alias: _91slug_93k0fHvPvUKoMeta?.alias || [],
    redirect: _91slug_93k0fHvPvUKoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/globaler-kryptomarkt/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0fHvPvUKoMeta?.name ?? "globaler-kryptomarkt-slug___en-ch",
    path: _91slug_93k0fHvPvUKoMeta?.path ?? "/en-ch/global-crypto-market/:slug",
    meta: _91slug_93k0fHvPvUKoMeta || {},
    alias: _91slug_93k0fHvPvUKoMeta?.alias || [],
    redirect: _91slug_93k0fHvPvUKoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/globaler-kryptomarkt/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0fHvPvUKoMeta?.name ?? "globaler-kryptomarkt-slug___fr-ch",
    path: _91slug_93k0fHvPvUKoMeta?.path ?? "/fr-ch/marche-mondial-des-cryptomonnaies/:slug",
    meta: _91slug_93k0fHvPvUKoMeta || {},
    alias: _91slug_93k0fHvPvUKoMeta?.alias || [],
    redirect: _91slug_93k0fHvPvUKoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/globaler-kryptomarkt/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k0fHvPvUKoMeta?.name ?? "globaler-kryptomarkt-slug___it-ch",
    path: _91slug_93k0fHvPvUKoMeta?.path ?? "/it-ch/mercato-globali-delle-criptovalute/:slug",
    meta: _91slug_93k0fHvPvUKoMeta || {},
    alias: _91slug_93k0fHvPvUKoMeta?.alias || [],
    redirect: _91slug_93k0fHvPvUKoMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/globaler-kryptomarkt/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nTV3Zm82ayMeta?.name ?? "hilfe-slug___de-ch",
    path: _91slug_93nTV3Zm82ayMeta?.path ?? "/de-ch/hilfe/:slug",
    meta: _91slug_93nTV3Zm82ayMeta || {},
    alias: _91slug_93nTV3Zm82ayMeta?.alias || [],
    redirect: _91slug_93nTV3Zm82ayMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/hilfe/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nTV3Zm82ayMeta?.name ?? "hilfe-slug___en-ch",
    path: _91slug_93nTV3Zm82ayMeta?.path ?? "/en-ch/help/:slug",
    meta: _91slug_93nTV3Zm82ayMeta || {},
    alias: _91slug_93nTV3Zm82ayMeta?.alias || [],
    redirect: _91slug_93nTV3Zm82ayMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/hilfe/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nTV3Zm82ayMeta?.name ?? "hilfe-slug___fr-ch",
    path: _91slug_93nTV3Zm82ayMeta?.path ?? "/fr-ch/assistance/:slug",
    meta: _91slug_93nTV3Zm82ayMeta || {},
    alias: _91slug_93nTV3Zm82ayMeta?.alias || [],
    redirect: _91slug_93nTV3Zm82ayMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/hilfe/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nTV3Zm82ayMeta?.name ?? "hilfe-slug___it-ch",
    path: _91slug_93nTV3Zm82ayMeta?.path ?? "/it-ch/supporto/:slug",
    meta: _91slug_93nTV3Zm82ayMeta || {},
    alias: _91slug_93nTV3Zm82ayMeta?.alias || [],
    redirect: _91slug_93nTV3Zm82ayMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/hilfe/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kv8X78D3VEMeta?.name ?? "hilfe-topic-category-slug___de-ch",
    path: _91slug_93Kv8X78D3VEMeta?.path ?? "/de-ch/hilfe/:topic/:category/:slug",
    meta: _91slug_93Kv8X78D3VEMeta || {},
    alias: _91slug_93Kv8X78D3VEMeta?.alias || [],
    redirect: _91slug_93Kv8X78D3VEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/hilfe/[topic]/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kv8X78D3VEMeta?.name ?? "hilfe-topic-category-slug___en-ch",
    path: _91slug_93Kv8X78D3VEMeta?.path ?? "/en-ch/help/:topic/:category/:slug",
    meta: _91slug_93Kv8X78D3VEMeta || {},
    alias: _91slug_93Kv8X78D3VEMeta?.alias || [],
    redirect: _91slug_93Kv8X78D3VEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/hilfe/[topic]/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kv8X78D3VEMeta?.name ?? "hilfe-topic-category-slug___fr-ch",
    path: _91slug_93Kv8X78D3VEMeta?.path ?? "/fr-ch/assistance/:topic/:category/:slug",
    meta: _91slug_93Kv8X78D3VEMeta || {},
    alias: _91slug_93Kv8X78D3VEMeta?.alias || [],
    redirect: _91slug_93Kv8X78D3VEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/hilfe/[topic]/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kv8X78D3VEMeta?.name ?? "hilfe-topic-category-slug___it-ch",
    path: _91slug_93Kv8X78D3VEMeta?.path ?? "/it-ch/supporto/:topic/:category/:slug",
    meta: _91slug_93Kv8X78D3VEMeta || {},
    alias: _91slug_93Kv8X78D3VEMeta?.alias || [],
    redirect: _91slug_93Kv8X78D3VEMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/hilfe/[topic]/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___de-ch",
    path: indexlaFeZuYZkUMeta?.path ?? "/de-ch",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en-ch",
    path: indexlaFeZuYZkUMeta?.path ?? "/en-ch",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___fr-ch",
    path: indexlaFeZuYZkUMeta?.path ?? "/fr-ch",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___it-ch",
    path: indexlaFeZuYZkUMeta?.path ?? "/it-ch",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "go___de-ch",
    path: indexp9V2p5fxycMeta?.path ?? "/de-ch/go",
    meta: indexp9V2p5fxycMeta || {},
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect || undefined,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "go___en-ch",
    path: indexp9V2p5fxycMeta?.path ?? "/en-ch/go",
    meta: indexp9V2p5fxycMeta || {},
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect || undefined,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "go___fr-ch",
    path: indexp9V2p5fxycMeta?.path ?? "/fr-ch/go",
    meta: indexp9V2p5fxycMeta || {},
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect || undefined,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "go___it-ch",
    path: indexp9V2p5fxycMeta?.path ?? "/it-ch/go",
    meta: indexp9V2p5fxycMeta || {},
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect || undefined,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  }
]